@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth
}
 
/* Theme Fonts */
.font-Opensans {
  font-family: 'Open Sans', sans-serif;
}

.font-Roboto {
  font-family: 'Roboto', sans-serif;
}

.font-Nunito {
  font-family: 'Nunito', sans-serif;
}

/* Basic Template */

.cv-template {
  @apply max-w-[90rem] w-full mx-auto relative overflow-hidden text-gray-800 bg-gray-100 after:bg-none after:bg-no-repeat after:bg-left-bottom after:w-[410px] after:h-[430px] md:after:w-[820px] md:after:h-[860px] after:absolute after:-bottom-[3.75rem] after:-left-[1.625rem] md:after:-bottom-28 md:after:-left-10 after:z-0 after:bg-cover
}


.cv-header {
  @apply relative flex flex-col lg:flex-row items-center lg:items-stretch justify-between pt-10 pb-12 sm:py-20 lg:py-24 px-5 sm:px-12 xl:px-24 2xl:px-36 gap-4 sm:gap-10 lg:gap-14 bg-white
}

.cv-scroll-btn {
  @apply flex flex-col items-center text-center absolute bottom-0 sm:bottom-1 left-1/2 -translate-x-1/2 gap-y-0.5 sm:gap-y-1.5 text-xs bg-transparent
}
 
.cv-scroll-btn  img{ 
  @apply w-5 sm:w-auto
}

.cv-header-text {
  @apply flex flex-col items-start;
}

.cv-header-text h2 {
  @apply font-extrabold text-2xl mb-3 sm:text-4xl sm:mb-6 lg:mt-7;
}

.cv-header-text h4 {
  @apply font-bold text-base mb-3 sm:text-lg sm:mb-7;
}

.cv-header-text p {
  @apply text-sm sm:text-lg opacity-80 mb-3 sm:mb-7; 
}

.cv-header-text button {
  @apply hidden lg:block bg-gray-800 border border-gray-800 text-xs sm:text-lg rounded-lg px-4 sm:px-14 h-8 sm:h-14  text-white font-extrabold mt-4 sm:mt-10 lg:mt-auto mb-4 sm:mb-8 active:scale-95 whitespace-nowrap;
}

.video-btn-xs {
  @apply block lg:hidden bg-gray-800 border border-gray-800 text-xs sm:text-lg rounded-lg px-4 sm:px-14 h-8 sm:h-14  text-white font-extrabold active:scale-95 whitespace-nowrap;
}

.cv-header-video {
  @apply w-80 sm:w-[666px] h-52 sm:h-[438px] bg-no-repeat bg-contain flex items-center justify-center flex-shrink-0
}

.cv-header-video iframe {
  @apply w-[90%] sm:w-[540px] h-44 sm:h-[349px] rounded-[20px] border-[6px] sm:border-[12px] border-gray-200
}

.cv-body {
  @apply flex flex-col relative z-10
}

.cv-body-item {
  @apply flex flex-col lg:flex-row odd:py-10 gap-x-8 sm:odd:py-20 even:py-7 sm:even:py-14 px-5 sm:px-12 xl:px-24 2xl:px-36 gap-5 sm:gap-10 lg:gap-16 odd:bg-transparent even:bg-white;
}

.cv-body-item h4 {
  @apply font-extrabold text-lg sm:text-2xl sm:w-80 xl:w-[342px] flex-shrink-0;
}

.cv-body-content {
  @apply flex-1 relative top-2 pl-2 lg:pl-0
}

.cv-body-content ol {
  @apply border-l border-gray-300 flex flex-col gap-y-8 sm:gap-y-10
}

.cv-body-item-header {
  @apply flex items-center;
}

.cv-body-item-header .dot {
  @apply bg-gray-600 w-2 h-2 -mt-3 -ml-1 mr-5 sm:w-4 sm:h-4 rounded-full sm:-mt-3 sm:-ml-2 sm:mr-10 flex-shrink-0
}

.cv-body-item-header p{
  @apply font-extrabold text-sm sm:text-xl -mt-3;
}

.cv-body-item-header p a {
  @apply duration-200 hover:text-primary hover:underline;
}

.cv-body-item-body {
  @apply  mt-1.5 ml-6 sm:mt-3.5 sm:ml-12
}

.cv-body-item-body h6 {
  @apply text-xs mb-2 sm:text-lg sm:mb-4 opacity-70;
}

.cv-body-item-body p {
  @apply text-xs sm:text-lg;
}

.cv-footer {
  @apply flex pt-8 px-5 pb-8 gap-5 sm:pt-16 sm:px-12 xl:px-36 2xl:px-52 sm:pb-20 lg:pb-32 sm:gap-x-10 relative z-10
}

.cv-footer-content {
  @apply bg-white  py-6 gap-x-8 rounded-lg px-6 sm:px-12 lg:px-28 sm:py-9 w-full grid grid-cols-1 lg:grid-cols-3 justify-between gap-6 md:gap-6 sm:rounded-[20px];
}

.cv-footer-unit {
  @apply flex-1
}

.cv-footer-unit h5 {
  @apply font-extrabold text-sm mb-1.5 sm:text-2xl sm:mb-3;
}

.cv-footer-unit a {
  @apply text-primary text-xs gap-x-0.5 sm:text-lg flex items-center sm:gap-x-1.5 w-full;
}

.cv-footer-unit a span {
  @apply truncate
}

.cv-footer-unit a img {
  @apply w-4 md:w-auto
}

/* CV Preview */

.cv-preview.cv-template {
  @apply max-w-[45rem] after:w-[410px] after:h-[430px] after:-bottom-[3.75rem] after:-left-[1.625rem]
}
 
.cv-preview .cv-header {
  @apply flex-col md:flex-row sm:py-12 sm:px-16 gap-x-7 bg-white
}
 
.cv-preview .cv-header-text h2 {
  @apply text-xl mb-3 mt-2.5;
}

.cv-preview .cv-header-text h4 {
  @apply text-sm mb-3;
}

.cv-preview .cv-header-text p {
  @apply text-sm mb-3; 
}

.cv-preview .cv-header-text button {
  @apply text-xs px-4 h-8 mb-4 hidden md:block;
}

.cv-preview .video-btn-xs {
  @apply text-xs px-4 h-8 mb-4 block md:hidden;
}



.cv-preview .cv-header-video {
  @apply sm:w-[333px] sm:h-[219px]
}

.cv-preview .cv-header-video iframe {
  @apply sm:w-[270px] sm:h-[175px] rounded-xl border-[6px]
}

.cv-preview .cv-body-item {
  @apply sm:odd:py-10 sm:even:py-7 sm:px-16 gap-x-8
}

.cv-preview .cv-body-item h4 {
  @apply text-sm w-[171px];
}

.cv-preview .cv-body-content {
  @apply top-1
}

.cv-preview .cv-body-content ol {
  @apply gap-y-8
}

.cv-preview .cv-body-item-header .dot {
  @apply w-2 h-2 -mt-3 -ml-1 mr-5
}

.cv-preview .cv-body-item-header p{
  @apply text-xs -mt-3;
}
 

.cv-preview .cv-body-item-body {
  @apply mt-1.5 ml-6
}

.cv-preview .cv-body-item-body h6 {
  @apply text-xs mb-2;
}

.cv-preview .cv-body-item-body p {
  @apply text-xs;
}

.cv-preview .cv-footer {
  @apply sm:pt-8 sm:px-10 md:px-16 lg:px-24 sm:pb-16 gap-x-5
}

.cv-preview .cv-footer-content {
  @apply grid-cols-1 md:grid-cols-3 px-6 py-3 gap-x-8 rounded-lg;
}

.cv-preview .cv-footer-unit h5 {
  @apply text-sm mb-1.5;
}

.cv-preview .cv-footer-unit a {
  @apply text-xs gap-x-0.5;
}

.cv-preview .cv-footer-unit img {
  @apply w-3
}


/* Theme Colorful */

.theme-colorful.cv-template {
  @apply text-neutral bg-[#FBF9F9] after:bg-playful-footer;
}

.theme-colorful .cv-header-text h2 {
  @apply text-primary;
}

.theme-colorful .cv-header-text button {
  @apply bg-primary border-primary text-white;
}

.theme-colorful .video-btn-xs {
  @apply bg-primary border-primary text-white;
}



.theme-colorful .cv-header-video {
  @apply bg-playful-video;
}

.theme-colorful .cv-header-video iframe {
  @apply border-[#FBF9F9]
}

.cv-body-item {
  @apply odd:bg-[#FBF9F9] even:bg-white;
}

.theme-colorful .cv-body-item-header .dot {
  @apply bg-primary
}
 
.theme-colorful .cv-body-content ol {
  @apply  border-primary
}


/* Theme Softcolor */

.theme-softColor.cv-template {
  @apply text-neutral bg-white after:bg-softColor-footer;
}

.theme-softColor .cv-header {
  @apply bg-[#FAFBFC]
}

.theme-softColor .cv-header-text button {
  @apply bg-[#E9F2FC] border-[#E9F2FC] text-neutral;
}

.theme-softColor .video-btn-xs {
  @apply bg-[#E9F2FC] border-[#E9F2FC] text-neutral;
}



.theme-softColor .cv-header-video {
  @apply bg-softColor-video;
}

.theme-softColor .cv-header-video iframe {
  @apply border-[#E9F2FC]
}

.theme-softColor .cv-body-item-header .dot {
  @apply bg-[#747982]
}
 
.theme-softColor .cv-body-content ol {
  @apply border-[#747982]
}

.theme-softColor .cv-body-item {
  @apply even:bg-transparent;
}
 
.theme-softColor .cv-footer-content {
  @apply bg-[#FAFBFC] border border-primary;
}

/* Theme MonoChrome */

.theme-monoChrome.cv-template {
  @apply text-neutral bg-white after:bg-light-footer;
}

.theme-monoChrome .cv-header {
  @apply bg-white
}

.theme-monoChrome .cv-header-text button {
  @apply bg-primary border-primary text-white;
}

.theme-monoChrome .video-btn-xs {
  @apply bg-primary border-primary text-white;
}


.theme-monoChrome .cv-header-video {
  @apply bg-light-video;
}

.theme-monoChrome .cv-header-video iframe {
  @apply border-2 border-primary
}

.theme-monoChrome .cv-body-item-header .dot {
  @apply bg-white border border-primary
}
 
.theme-monoChrome .cv-body-content ol {
  @apply border-primary
}

.theme-monoChrome .cv-body-item {
  @apply even:bg-transparent;
}
 
.theme-monoChrome .cv-footer-content {
  @apply bg-white border border-primary;
}
 